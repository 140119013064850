import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AgreementAnnexureService } from "app/agreement-annexures/services/agreement-annexure.service";
import { TokenService } from "app/token.service";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  loggedInUserRoleId: any;
  loggedInUserId: any;
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    this.loggedInUserRoleId = localStorage.getItem("LoggedUserRole");
    this.loggedInUserId = localStorage.getItem("LoggedUserID");
    if (
      this.loggedInUserRoleId != undefined &&
      this.loggedInUserRoleId != null &&
      this.loggedInUserRoleId != "" &&
      this.loggedInUserId != undefined &&
      this.loggedInUserId != null &&
      this.loggedInUserId != ""
    ) {
      return true;
    } else {
      this.router.navigate(["authentication/login"]);
      return false;
    }
  }
}
