import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { AgreementAnnexure } from "app/agreement-annexures/models/agreement-annexure.dto";
import { AgreementAnnexureService } from "app/agreement-annexures/services/agreement-annexure.service";
import { AuthService } from "app/AuthGaurds/auth.service";
import { TokenService } from "app/token.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AnnexureSelfServiceGuard implements CanActivate {
  annexureDto: AgreementAnnexure = new AgreementAnnexure();
  loggedInUserRoleId: any;
  loggedInUserId: any;
  constructor(    
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService
  ) {}

  annexureId: any;
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this.loggedInUserRoleId = localStorage.getItem("LoggedUserRole");
    this.loggedInUserId = localStorage.getItem("LoggedUserID");
    this.annexureId = route.paramMap.get("id");

    if (
      this.loggedInUserRoleId != undefined &&
      this.loggedInUserRoleId != null &&
      this.loggedInUserRoleId != "" &&
      this.loggedInUserId != undefined &&
      this.loggedInUserId != null &&
      this.loggedInUserId != ""
    ) {
      var isAnnexureAccessible =  await this.authService.isAnnexureAccessible(this.annexureId, this.loggedInUserId);
      if(isAnnexureAccessible){ return true;}
      else{
        this.router.navigate(["authentication/login"]);
        return false;}
     
     
    } else {     
      this.router.navigate(["authentication/login"]);
      return false;
    }
  }

  errormethod(error: any) {
    if (error.status === 401) {
      this.router.navigate(["/employeeconfig/401"]);
    }
    if (error.status === 500) {
      this.router.navigate(["/employeeconfig/500"]);
    }
    if (error.status === 404) {
      this.router.navigate(["/employeeconfig/404"]);
    }

    if (error.status === 0) {
      this.router.navigate(["/employeeconfig/0"]);
    }
  }
}
