import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AgreementAnnexure } from "app/agreement-annexures/models/agreement-annexure.dto";
import { AgreementAnnexureService } from "app/agreement-annexures/services/agreement-annexure.service";

import { Observable, async, of } from "rxjs";
import "rxjs/add/observable/of";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  annexureDto: AgreementAnnexure = new AgreementAnnexure();

  constructor(
    private annexureService: AgreementAnnexureService,
    private router: Router
  ) {}

  async isAnnexureAccessible(annexureId: any, loggedInUserId: any) :Promise<boolean> {
    {
      var id = annexureId;
      var usedId = loggedInUserId;
      this.annexureDto = await this.annexureService
        .getAnnexure(annexureId)
        .toPromise();

      if (this.annexureDto != undefined && this.annexureDto != null) {
        if (this.annexureDto.EmployeeId == loggedInUserId) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  errormethod(error: any) {
    if (error.status === 401) {
      this.router.navigate(["/employeeconfig/401"]);
    }
    if (error.status === 500) {
      this.router.navigate(["/employeeconfig/500"]);
    }
    if (error.status === 404) {
      this.router.navigate(["/employeeconfig/404"]);
    }

    if (error.status === 0) {
      this.router.navigate(["/employeeconfig/0"]);
    }
  }
}
