import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { TokenService } from './token.service';
import {Observable} from 'rxjs/Rx';
// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';


@Injectable({
  providedIn: 'root'
})
export class ApplicationhttpService {

  baseUrl: string;
  secondaryUrl: string;
  headerDict = {
    'enctype': 'multipart/form-data',
  }
  profilePicUrl: string;
  _errHtml: string = '';
  blob: Blob;
  filedata: any;
  emailPattern=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  _requestOptions = {
    headers: new HttpHeaders(this.headerDict),
  };
  private messageSource = new BehaviorSubject<string>('service');
  currentMessage = this.messageSource.asObservable();

 

  constructor(private httpClient: HttpClient, private tokenService: TokenService
    //, private keepalive: Keepalive, private idle: Idle
    ) {

// below is for Local Development Server

   //  this.baseUrl = 'http://localhost:51108/';
    this.baseUrl = 'https://devapi.irtsaas.com/';
    this.secondaryUrl = ''

// below is for Production server Hosting

// this.baseUrl = "https://api.irtsaas.com/";

// below is for Development server Hosting

// this.baseUrl = "https://stageapi.irtsaas.com/";

  }
  
  public postmethodforLogin(username, password) {
    var userData = "username=" + username + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
    return this.httpClient.post(this.baseUrl + 'token', userData, { headers: reqHeader });

  }

  public postmethodforRefresh(refresh_token) {
    this.tokenService.removeToken();
    this.tokenService.removeRefreshToken();
    var userData = "refresh_token=" + refresh_token + "&grant_type=refresh_token";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'No-Auth': 'True' });
    return this.httpClient.post(this.baseUrl + 'token', userData, { headers: reqHeader });

  }


  public refreshaccess_token(){
    const refreshToken = this.tokenService.getRefreshToken();
    this.postmethodforRefresh(refreshToken)
    .subscribe(data => {
      let result: any = data;
      this.tokenService.saveToken(result.access_token);
      this.tokenService.saveRefreshToken(result.refresh_token);
      this.tokenService.setUserLoggedIn(true);
      location.reload();
    });
  }
  public Getmethod(url: string) {
    return this.httpClient.get(this.baseUrl + url);
  }

  public Postmethod(url: string, data: any) {
    return this.httpClient.post(this.baseUrl + url, data);
  }

  public PostmethodforUpload(url: string, data: any) {
    let headers = new HttpHeaders();
//this is the important step. You need to set content type as null
    headers.set('Content-Type', null);
    headers.set('Accept', "multipart/form-data");
    let params = new HttpParams();
    return this.httpClient.post(this.baseUrl + url, data,{ params,headers});
  }


  public getPdf(url: string) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpClient.get(this.baseUrl + url, httpOptions);
  }

  public PdfdownLoad_post(url: string,obja:any) {
    const httpOptions = {
      responseType: 'blob' as 'json',
    };
    return this.httpClient.post(this.baseUrl + url,obja, httpOptions);
  }


  public downloadPdf(url: string, fileName: string) {
    this.getPdf(url).subscribe((data) => {
      this.filedata = data;
      this.blob = new Blob([this.filedata], { type: "application/pdf" });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = fileName;
      link.click();
    });
  }
  
  public getMimetype(checkFileType: string) {
    let fileType = '';
    if (checkFileType == ".txt") {
      fileType = "text/plain";
    }
    if (checkFileType == ".pdf") {
      fileType = "application/pdf";
    }
    if (checkFileType == ".doc") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == ".docx") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == ".xls") {
      fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == ".png") {
      fileType = "image/png";
    }
    if (checkFileType == ".jpg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == ".jpeg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == ".gif") {
      fileType = "image/gif";
    }
    if (checkFileType == ".csv") {
      fileType = "text/csv";
    }
    return fileType;
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  //Everify
  public errorFunction(errors) {
    this._errHtml = '';
    this._errHtml =
      `<table class='table table-bordered'>
    <thead>
      <th>Error Code</th>
      <th>Description</th>
    </thead>
    <tbody>`
      ;
    for (let i = 0; i < errors.length; i++) {
      this._errHtml = this._errHtml + `<tr style='text-align: left;'><td>` + errors[i].error_code + '</td><td>' + errors[i].message + '</td></tr>';
    }
    this._errHtml = this._errHtml + '</tbody>'

    Swal.fire({
      title: '<strong>Errors From E-Verify System</u></strong>',
      // type: 'error',
      width: '52rem',
      html:
        this._errHtml,
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      customClass: 'swal-wide'
    })
  }

  public refreshToken() {
    const body = {
      'access_token': localStorage.getItem("access_token_everify"),
      'user_id': localStorage.getItem("LoggedUserID"),
      'expiry_date': localStorage.getItem("token_expiration_time"),
    }
    return new Promise(resolve => {
      this.Postmethod('/api/Everify/RefreshToken', body).subscribe(data => {
        if (data != "") {
          let result: any = data;
          localStorage.setItem('access_token_everify', result.access_token);
          localStorage.setItem('token_expiration_time', result.token_expiration_time);
          localStorage.setItem('account_id', result.user_info.account_id);
        }
        resolve(true);
      },
        error => {
          if (error.error.status == 500) {
            Swal.fire('', error.error.statusText, 'error');
          } else {
            if (error.error.errors.length > 0) {
              this.errorFunction(error.error.errors);
            }
          }
          resolve(false);
        });
      resolve(true);
    });
  }


 



}