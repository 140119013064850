import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { NewSiteComponent } from './new-site/new-site.component';
import { AuthLayoutComponent } from './auth/auth-layout.component';
import { ForbiddenComponent } from './AuthGaurds/forbidden/forbidden.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'authentication',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    children: [{
      path: 'dashboard',
      loadChildren: './dashboard/dashboard.module#DashboardModule'
    },
    {
      path: 'hr',
      loadChildren: './hr/hr.module#HrModule'
    },
    {
      path: 'organization',
      loadChildren: './organization/organization.module#OrganizationModule'
    },
    {
      path: 'employeeconfig',
      loadChildren: './employeeconfig/employeeconfig.module#EmployeeconfigModule'
    },
    {
      path: 'appraisals',
      loadChildren: './appraisals/appraisals.module#AppraisalsModule'
    },
    {
      path: 'help',
      loadChildren: './help/help.module#HelpModule'
    },
    {
      path: 'recruitments',
      loadChildren: './recruitments/recruitments.module#RecruitmentsModule'
    },

    {
      path: 'time',
      loadChildren: './time/time.module#TimeModule'
    },
    {
      path: 'employee',
      loadChildren: './employee/employee.module#EmployeeModule'
    },
    {
      path: 'selfservice',
      loadChildren: './selfservice/selfservice.module#SelfserviceModule'
    },
    {
      path: 'preemployment',
      loadChildren: './preemployment/preemployment.module#PreemploymentModule'
    },
    {
      path: 'everify',
      loadChildren: './everify/everify.module#EverifyModule'
    },
    {
      path: 'ei9storagesys',
      loadChildren: './ei9storagesys/ei9storagesys.module#Ei9storagesysModule'
    },
    {
      path: 'epublicaccessfilesystem',
      loadChildren: './epublicaccessfilesystem/epublicaccessfilesystem.module#EpublicaccessfilesystemModule'
    },
    {
      path: 'payrollmodule',
      loadChildren: './payrollmodule/payrollmodule.module#PayrollmoduleModule'
    },
    {
      path: 'fouroonemodule',
      loadChildren: './fouroonemodule/fouroonemodule.module#FouroonemoduleModule'
    },
    {
      path: 'medicaldentalvisionmodule',
      loadChildren: './medicaldentalvisionmodule/medicaldentalvisionmodule.module#MedicaldentalvisionmoduleModule'
    },
    {
      path: 'immigrationmodule',
      loadChildren: './immigrationmodule/immigrationmodule.module#ImmigrationmoduleModule'
    },
    {
      path: 'uspscouriermodule',
      loadChildren: './uspscouriermodule/uspscouriermodule.module#USPScouriermoduleModule'
    },
    {
      path: 'passportmodule',
      loadChildren: './passportmodule/passportmodule.module#PassportmoduleModule'
    },
    {
      path: 'healthmodule',
      loadChildren: './healthmodule/healthmodule.module#HealthmoduleModule'
    },
    {
      path: 'companyinfomodule',
      loadChildren: './companyinfomodule/companyinfomodule.module#CompanyinfomoduleModule'
    },
    {
      path: 'contractmanagementmodule',
      loadChildren: './contractmanagementmodule/contractmanagementmodule.module#ContractmanagementmoduleModule'
    },
    {
      path: 'businessinsurancemanagementmodule',
      loadChildren: './businessinsurancemanagementmodule/businessinsurancemanagementmodule.module#BusinessinsurancemanagementmoduleModule'
    },
    {
      path: 'e-fone-student-maintenance-system',
      loadChildren: './e-fone-student-maintenance-system/e-fone-student-maintenance-system.module#EFOneStudentMaintenanceSystemModule'
    },
    {
      path: 'benefitsmanagementsystemmodule',
      loadChildren: './benefitsmanagementsystemmodule/benefitsmanagementsystemmodule.module#BenefitsmanagementsystemmoduleModule'
    }
    ,
    {
      path: 'physicalmailcorrespondencemodule',
      loadChildren: './physicalmailcorrespondencemodule/physicalmailcorrespondencemodule.module#PhysicalmailcorrespondencemoduleModule'
    },
    {
      path: 'telephonelogsmodule',
      loadChildren: './telephonelogsmodule/telephonelogsmodule.module#TelephonelogsmoduleModule'
    },
    {
      path: 'technologytrainingsmodule',
      loadChildren: './technologytrainingsmodule/technologytrainingsmodule.module#TechnologytrainingsmoduleModule'
    },
    {
      path: 'technologynewsmodule',
      loadChildren: './technologynewsmodule/technologynewsmodule.module#TechnologynewsmoduleModule'
    },
    {
      path: 'businessemailmodule',
      loadChildren: './businessemailmodule/businessemailmodule.module#BusinessemailmoduleModule'
    },
    {
      path: 'compliancemanagementmodule',
      loadChildren: './compliancemanagementmodule/compliancemanagementmodule.module#CompliancemanagementmoduleModule'
    },
    {
      path: 'accountingmanagementmodule',
      loadChildren: './accountingmanagementmodule/accountingmanagementmodule.module#AccountingmanagementmoduleModule'
    },
    {
      path: 'releasenotemodule',
      loadChildren: './releasenotemodule/releasenotemodule.module#ReleasenotemoduleModule'
    },
    {
      path: 'performance',
      loadChildren: './performance/performance.module#PerformanceModule'
    },
    {
      path: 'timeandworkreportmodule',
      loadChildren: './timeandworkreportmodule/timeandworkreportmodule.module#TimeandworkreportmoduleModule'
    },
    {
      path: 'leavemanagement',
      loadChildren: './leavemanagement/leavemanagement.module#LeavemanagementModule'
    },
    {
      path: 'training',
      loadChildren: './training/training.module#TrainingModule'
    },
    {
      path: 'servicerequest',
      loadChildren: './servicerequest/servicerequest.module#ServicerequestModule'
    },
    {
      path: 'correspondence',
      loadChildren: './correspondence/correspondence.module#CorrespondenceModule'
    },
    {
      path: 'calendar',
      loadChildren: './calendar/calendar.module#CalendarModule'
    },
    {
      path: 'tasks',
      loadChildren: './tasks/tasks.module#TasksModule'
    },
    {
      path: 'notes',
      loadChildren: './notes/notes.module#NotesModule'
    },
    {
      path: 'technicalcertificationmodule',
      loadChildren: './technicalcertificationmodule/technicalcertificationmodule.module#TechnicalcertificationmoduleModule'
    },
    {
      path: 'docrepositorymodule',
      loadChildren: './docrepositorymodule/docrepositorymodule.module#DocrepositorymoduleModule'
    },
    {
      path: 'employmentmodule',
      loadChildren: './employmentmodule/employmentmodule.module#EmploymentmoduleModule'
    },
    {
      path: 'assetsmodule',
      loadChildren: './assetsmodule/assetsmodule.module#AssetsmoduleModule'
    },
    {
      path: 'expensemanagementmodule',
      loadChildren: './expensemanagementmodule/expensemanagementmodule.module#ExpensemanagementmoduleModule'
    },
    {
      path: 'referralmodule',
      loadChildren: './referralmodule/referralmodule.module#ReferralmoduleModule'
    },
    {
      path: 'jobopportunitiesmodule',
      loadChildren: './jobopportunitiesmodule/jobopportunitiesmodule.module#JobopportunitiesmoduleModule'
    },
    {
      path: 'archivesmodule',
      loadChildren: './archivesmodule/archivesmodule.module#ArchivesmoduleModule'
    },
    {
      path: 'holidaysmodule',
      loadChildren: './holidaysmodule/holidaysmodule.module#HolidaysmoduleModule'
    },
    {
      path: 'remindersmodule',
      loadChildren: './remindersmodule/remindersmodule.module#RemindersmoduleModule'
    },
    {
      path: 'essentiallinksmodule',
      loadChildren: './essentiallinksmodule/essentiallinksmodule.module#EssentiallinksmoduleModule'
    },
    {
      path: 'reportsmodule',
      loadChildren: './reportsmodule/reportsmodule.module#ReportsmoduleModule'
    },
    {
      path: 'globaldatasolutionswebsite',
      loadChildren: './globaldatasolutionswebsite/globaldatasolutionswebsite.module#GlobaldatasolutionswebsiteModule'
    }
    
    ]
  },
  {
    path: '',
    component: NewSiteComponent,
    children: [{
      path: 'siteconfig',
      loadChildren: './siteconfig/siteconfig.module#SiteconfigModule'
    },
    {
      path: 'organizationconfig',
      loadChildren: './siteconfig/siteconfig.module#SiteconfigModule'
    }]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'authentication',
      loadChildren: './session/session.module#SessionModule'
    }]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    children: [{
      path: 'authentication',
      loadChildren: './session/session.module#SessionModule'
    }]
  }, {path:'forbidden', component: ForbiddenComponent}

];