import {Component, OnInit} from '@angular/core';
import {Renderer2, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {UserNotification} from 'app/dashboard/notification/notification.dto';
import {NotificationService} from 'app/dashboard/notification/notification.service';
import {TokenService} from 'app/token.service';
import * as $ from 'jquery';
import swal from "sweetalert2";
@Component({
    selector: 'ms-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
    notifications: UserNotification[];
    notificationsCount: number;
    loggedInUserId: number;
    LoggedUserRole: number;
    numberOfUnreadNotifications: number;

    constructor(
        private tokenService: TokenService,
        private router: Router,
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private _document,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit() {
        var user = localStorage.getItem('LoggedUserID');
        this.LoggedUserRole = parseInt(localStorage.getItem('LoggedUserRole'));

        if (user != undefined && user != null && user != '') {
            this.tokenService.setUserLoggedIn(true);
            this.loggedInUserId = parseInt(localStorage.getItem('LoggedUserID'));
        } else {
            this.tokenService.setUserLoggedIn(false);
            this.router.navigate(['authentication/login']);
        }

        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.src = 'assets/script.js';
        s.text = `alert('alert alert');`;
        this.renderer2.appendChild(this._document.body, s);
        this.getNotifications();
        this.getUnreadNotificationsCount();

        // dialog.addEventListener('click', (e) => {
        //   console.log('e', e);
        //   const dialogDimensions = dialog.getBoundingClientRect()
        //   if (
        //       e.clientX < dialogDimensions.left ||
        //       e.clientX > dialogDimensions.right ||
        //       e.clientY < dialogDimensions.top ||
        //       e.clientY > dialogDimensions.bottom
        //   ) {
        //     console.log('hi')
        //   }
        // })

        // $(this.myButton.nativeElement).click(() => {
        //   alert('Button clicked using jQuery');
        // });

        // const dialog = document.querySelector('.notifi-box');
        // // You can directly access event properties here
        //
        // const dialogDimensions = dialog.getBoundingClientRect()
        // console.log('dialogDimensions', dialogDimensions)
        // if (
        //     event.clientX < dialogDimensions.left ||
        //     event.clientX > dialogDimensions.right ||
        //     event.clientY < dialogDimensions.top ||
        //     event.clientY > dialogDimensions.bottom
        // ) {
        //   console.log('hi')
        // } else {
        //   console.log('bye')
        // }
        // $(document).on('click touch', function (event) {
        //   console.log(event);
        //   if (!$(event.target).parents().addBack().is('.notification-icon-top')) {
        //     $('.notifi-box').hide();
        //   }
        // });
        // $(document).on('click touch', function(event) {
        //   if (!$(event.target).closest('.notifi-box').length && !$(event.target).is('.notification-icon-top')) {
        //   //  $('.notifi-box').hide();
        //   }
        //   console.log($(event.target));
        //   if(!$(event.target).parents('.notifi-item') || !$(event.target).parents('.all-read-heading') || !$(event.target).parents('.icon notification-icon-top') ){
        //     $('.notifi-box').hide();
        //   }
        // });
        // // Stop propagation to prevent hiding "#tooltip" when clicking on it
        // $('.notifi-box').on('click touch', function (event) {
        //   event.stopPropagation();
        // });
    }


    //   closeNotification(event: MouseEvent): void {
    //     const dialog = document.querySelector('.notifi-box');
    //   // You can directly access event properties here
    //   console.log('Click coordinates:', event.clientX, event.clientY);
    //       const dialogDimensions = dialog.getBoundingClientRect()
    //     console.log('dialogDimensions', dialogDimensions)
    //       if (
    //           event.clientX < dialogDimensions.left ||
    //           event.clientX > dialogDimensions.right ||
    //           event.clientY < dialogDimensions.top ||
    //           event.clientY > dialogDimensions.bottom
    //       ) {
    //         console.log('hi')
    //       } else {
    //         console.log('bye')
    //       }
    //
    //   // Do something based on the event
    //   // For example, close a dialog or perform an action
    // }

    getNotifications() {
        this.notificationService
            .getNotifications(this.loggedInUserId, 'ALL')
            .subscribe(
                (data) => {
                    this.notifications = data;
                    this.notificationsCount = this.notifications.length;
                },
                (error) => {
                    this.errormethod(error);
                }
            );
    }

    getUnreadNotificationsCount()
    {
        this.notificationService
        .getUnreadNotificationsCount(this.loggedInUserId)
        .subscribe(
            (data) => {
                this.numberOfUnreadNotifications = data;
                         
            },
            (error) => {
                this.errormethod(error);
            }
        );

    }
    onMarkAsRead(notification: UserNotification) {

        this.notificationService.markAsRead(notification).subscribe(
            (data) => {
                this.getNotifications();
                this.getUnreadNotificationsCount();
            },
            (error) => {
                this.errormethod(error);
            }
        );
    }

    onMarkAllAsRead() {
        
        this.notificationService.markAllAsRead(this.loggedInUserId).subscribe(
            (data) => {
                
                this.getNotifications();
                this.getUnreadNotificationsCount();
            },
            (error) => {
                this.errormethod(error);
            }
        );
    }

  


    onViewDetails(notification: UserNotification) {
        this.notificationService.markAsRead(notification).subscribe(
            () => {
                this.router.navigate([notification.Url]);               
                this.toggleNotifi();
                this.getNotifications();
                this.getUnreadNotificationsCount();
            },
            (error) => {
                this.errormethod(error);
            }
        );
    }
    errormethod(error: any) {
        if (error.status === 401) {
          this.router.navigate(["/employeeconfig/401"]);
        }
        if (error.status === 500) {
          this.router.navigate(["/employeeconfig/500"]);
        }
        if (error.status === 404) {
          this.router.navigate(["/employeeconfig/404"]);
        }
        if (error.status === 400) {
          swal.fire("", error.statusText, "error");
        }
        if (error.status === 0) {
          this.router.navigate(["/employeeconfig/0"]);
        }
      }

    getNotificationItemUrl(notification: UserNotification): string {
        switch (notification.SubjectId) {
            case 1: {
                if (this.LoggedUserRole == 3) {
                } else {
                }
                break;
            }
            case 2: {
                if (this.LoggedUserRole == 3) {
                } else {
                }
                break;
            }
            case  3: {
                if (this.LoggedUserRole == 3) {
                } else {
                }
                break;
            }
        }

        return '';
    }


    toggleNotifi() {
        const notificationBox = document.querySelector('.notifi-box');
        const notificationBackdrop = document.querySelector('#noticeable-backdrop');

        if (notificationBox.classList.contains('expand-notification')) {
            notificationBox.classList.remove('expand-notification');
            notificationBackdrop.classList.remove('backdropEnabled');
        } else {
            notificationBox.classList.add('expand-notification');
            notificationBackdrop.classList.add('backdropEnabled');
        }
    }

    closeNotification() {
        const notificationBox = document.querySelector('.notifi-box');
        const notificationBackdrop = document.querySelector('#noticeable-backdrop');

        if (notificationBox.classList.contains('expand-notification')) {
            notificationBox.classList.remove('expand-notification');
            notificationBackdrop.classList.remove('backdropEnabled');
        } else {
            notificationBox.classList.add('expand-notification');
            notificationBackdrop.classList.add('backdropEnabled');
        }
    }

    onBackdropClick() {
        const notificationBox = document.querySelector('.notifi-box');
        const notificationBackdrop = document.querySelector('#noticeable-backdrop');

        if (notificationBox.classList.contains('expand-notification')) {
            notificationBox.classList.remove('expand-notification');
            notificationBackdrop.classList.remove('backdropEnabled');
        } else {
            notificationBox.classList.add('expand-notification');
            notificationBackdrop.classList.add('backdropEnabled');
        }
    }

}

