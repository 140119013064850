import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserNotification } from './notification.dto';
import { Observable } from 'rxjs';
import { environment } from "environments/environment";
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl: string = "";
  constructor(private httpClient: HttpClient) { this.baseUrl = environment.apiURL;}

  getNotifications(
    employeeId: number,
    readStatus: string
  ): Observable<UserNotification[]> {
    return this.httpClient.get<UserNotification[]>(
      `${this.baseUrl}/api/Notification/notificationsbyemployee?EmployeeId=${employeeId}&NotificationStatus=${readStatus}`
    );
  }

  getUnreadNotificationsCount(employeeId: number): Observable<number> {

    return this.httpClient.get<number>(`${this.baseUrl}/api/Notification/unreadnotificationscount?EmployeeId=${employeeId}`);
  }

  
  markAsRead(notification: UserNotification) : Observable<any> {

    return this.httpClient.put<any>(`${this.baseUrl}/api/Notification/markasread`, notification);

  }
  markAllAsRead(employeeId:number): Observable<any> {

    return this.httpClient.put<any>(`${this.baseUrl}/api/Notification/markallasread`,employeeId);
  }
}
