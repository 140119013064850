import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MenuItems } from '../../core/menu/menu-items/menu-items';
import { HorizontalMenuItems } from '../../core/menu/menu-items/horizontal-menu-items';
import { PageTitleService } from '../../core/page-title/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute, RouterEvent, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MediaChange, ObservableMedia } from "@angular/flex-layout";
import PerfectScrollbar from 'perfect-scrollbar';
// import {
//   PerfectScrollbarConfigInterface
// } from 'ngx-perfect-scrollbar';
import swal from 'sweetalert2';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import{  ProfilePicComponent} from '../../profile-pic/profile-pic.component';
import { TokenService } from '../../token.service';
import { ApplicationhttpService } from '../../applicationhttp.service';

const screenfull = require('screenfull');

@Component({
  selector: "ms-left-navigation-menu",
  templateUrl: "./left-navigation-menu.component.html",
  styleUrls: ["./left-navigation-menu.component.scss"],
})
export class LeftNavigationMenuComponent {

@Output() leftMenuSelectedEvent =new EventEmitter<any>();

  selectedLeftMenuItem: any = null;
  private _router: Subscription;
  //header: string;
 // currentLang = "en";
  url: string;
  showSettings = false;
  dark: boolean;
  boxed: boolean;
  horizontalMenu: boolean;
  collapseSidebar: boolean;
  compactSidebar: boolean;
  customizerIn: boolean = false;
  headerGreen: boolean = false;
  headerRed: boolean = false;
  headerYellow: boolean = false;
  root = "ltr";
  myroot = "ltr";
  collapsedClass: any = "side-panel-opened";
  chatpanelOpen: boolean = false;
  themeHeaderSkinColor: any = "header-default";
  themeSidebarSkinColor: any = "sidebar-default";
  ProfileImagePath: string = undefined;
  UserID: any = undefined;
  UserProfile: any;

  private _mediaSubscription: Subscription;
  sidenavOpen: boolean = true;
  sidenavMode: string = "side";
  isMobile: boolean = false;
  private _routerEventsSubscription: Subscription;
  loding: boolean;
  menuIT: any;
  UserName: any;
  OrgImage: any;
  DynamicMenu: any;

  @ViewChild("sidenav") sidenav;

  //public config: PerfectScrollbarConfigInterface = {};
  constructor(
    public menuItems: MenuItems,
    private ser: ApplicationhttpService,
    public horizontalMenuItems: HorizontalMenuItems,
    private pageTitleService: PageTitleService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private media: ObservableMedia,
    private tokenService: TokenService,
    public dialog: MatDialog,

  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loding = true;
    }
    if (event instanceof NavigationEnd) {
      this.loding = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loding = false;
    }
    if (event instanceof NavigationError) {
      this.loding = false;
    }
  }
  ngOnInit() {
    this.ser.currentMessage.subscribe(
      (message) => (this.ProfileImagePath = message)
    );
    this.UserID = localStorage.getItem("LoggedUserID");
    this.UserName = localStorage.getItem("UserName");
    this.OrgImage = localStorage.getItem("OrgImage");
    this.GetDynamicMenu();
    this.GetProfilePhoto();
    this.pageTitleService.title.subscribe((val: string) => {
     // this.header = val;
    });

    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
      });

    if (
      this.url != "/session/login" &&
      this.url != "/session/register" &&
      this.url != "/session/forgot-password" &&
      this.url != "/session/lockscreen"
    ) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar-container ")
      );

      if (window.matchMedia(`(min-width: 960px)`).matches) {
        // Commented by ganesh
        const ps = new PerfectScrollbar(elemSidebar, {
          wheelSpeed: 2,
          suppressScrollX: true,
        });
      }
    }
    if (this.media.isActive("xs") || this.media.isActive("sm")) {
      this.sidenavMode = "over";
      this.sidenavOpen = false;
    }
    this._mediaSubscription = this.media
      .asObservable()
      .subscribe((change: MediaChange) => {
        let isMobile = change.mqAlias == "xs" || change.mqAlias == "sm";

        this.isMobile = isMobile;
        this.sidenavMode = isMobile ? "over" : "side";
        this.sidenavOpen = !isMobile;
      });

    this._routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.isMobile) {
        this.sidenav.close();
      }
    });
  }

  ngOnDestroy() {
    // this is commented by @Ganesh
  //  this._router.unsubscribe();
  //  this._mediaSubscription.unsubscribe();
  }

  isFullscreen: boolean = false;

  menuMouseOver(): void {
    if (
      window.matchMedia(`(min-width: 960px)`).matches &&
      this.collapseSidebar
    ) {
      this.sidenav.mode = "over";
    }
  }

  menuMouseOut(): void {
    if (
      window.matchMedia(`(min-width: 960px)`).matches &&
      this.collapseSidebar
    ) {
      this.sidenav.mode = "side";
    }
  }

  toggleFullscreen() {
    if (screenfull.enabled) {
      screenfull.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }

  toggleNotification() {

  }

  addClassToBody() {
    const body = document.querySelector('.page-content');
    const body1 = document.querySelector('body');
    
    if (body.classList.contains('expand')) {
      body.classList.remove('expand');
    }    else {
      body.classList.add('expand') ;
    }
    if (body1.classList.contains('expanded')) {
      body1.classList.remove('expanded');
    }    else {
      body1.classList.add('expanded') ;
    }
  }

  customizerFunction() {
    this.customizerIn = !this.customizerIn;
  }
  headerSkinColorFunction(color) {
    this.themeHeaderSkinColor = color;
  }
  sidebarSkinColorFunction(color) {
    this.themeSidebarSkinColor = color;
  }
  menuToggleFunc() {
    this.sidenav.toggle();

    if (this.collapsedClass == "side-panel-opened") {
      this.collapsedClass = "side-panel-closed";
    } else {
      this.collapsedClass = "side-panel-opened";
    }
  }
  addMenuItem(): void {
    this.menuItems.add({
      state: "pages",
      name: "SILK MENU",
      type: "sub",
      icon: "trending_flat",
      children: [
        { state: "blank", name: "SUB MENU1" },
        { state: "blank", name: "SUB MENU2" },
      ],
    });
  }

  onActivate(e: any, scrollContainer: any) {
    scrollContainer.scrollTop = 0;
  }
  onLeftMenuClick(leftMenuitem: any) {

    this.leftMenuSelectedEvent.emit(leftMenuitem);
    this.selectedLeftMenuItem = leftMenuitem;
    var mainMenuRoute = this.selectedLeftMenuItem.state;
    var childMenuRoute = this.selectedLeftMenuItem.children[0].state;
    console.log('left navigation');
   // this.router.navigate(['companyinfomodule/companywebsiteurl']);

    this.router.navigate([mainMenuRoute + "/" + childMenuRoute]);

  }

  GetDynamicMenu() {
    let roleID = localStorage.getItem("LoggedUserRole");
    if (roleID != null && roleID != "" && roleID != "0") {
      this.ser.Getmethod("api/Home/GetModules?id=" + roleID).subscribe(
        (data) => {
          this.DynamicMenu = data;
          console.log(this.DynamicMenu);
        },
        (error) => {
          this.errormethod(error);
        }
      );
    } else {
      this.router.navigate(["/authentication"]);
    }
  }

  refresh(): void {
    window.location.reload();
  }
  home() {
    this.selectedLeftMenuItem = null;
    this.router.navigate(["/dashboard"]);
  }

  GetProfilePhoto() {
    let roleID = localStorage.getItem("LoggedUserRole");
    if (roleID != null && roleID != "" && roleID != "0") {
      this.ser
        .Getmethod("api/Employee2/GetMainemppersonaldetails?id=" + this.UserID)
        .subscribe(
          (data) => {
            this.UserProfile = data;
            this.ser.changeMessage(this.UserProfile.imageUrl);
          },
          (error) => {
            this.errormethod(error);
          }
        );
    } else {
      this.router.navigate(["/authentication"]);
    }
  }
  openProfileDialog(): void {
    swal
      .fire({
        title: "Are you sure?",
        text: "You want to change your profile photo?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Change",
      })
      .then((result) => {
        if (result.value) {
          const dialogRef = this.dialog.open(ProfilePicComponent, {
            width: "500px",
          });
          let instance = dialogRef.componentInstance;
          dialogRef.afterClosed().subscribe((result) => {});
        }
      });
  }

  errormethod(error: any) {
    if (error.status === 401) {
      this.router.navigate(["/employeeconfig/401"]);
    }
    if (error.status === 500) {
      this.router.navigate(["/employeeconfig/500"]);
    }
    if (error.status === 404) {
      this.router.navigate(["/employeeconfig/404"]);
    }
    if (error.status === 400) {
      swal.fire("", error.statusText, "error");
    }
    if (error.status === 0) {
      this.router.navigate(["/employeeconfig/0"]);
    }
  }

  menuItem(event: any) {
    if (event == "E-Verify") {
      this.router.navigate(["/everify"]);
    }
  }

  Logout() {
    this.router.navigate(["/authentication"]);
    localStorage.removeItem("Token");
    localStorage.removeItem("LoggedUserID");
    localStorage.removeItem("LoggedUserRole");
    this.tokenService.setUserLoggedIn(false);
    this.tokenService.removeRefreshToken();
    this.tokenService.removeToken();
    for (var obj in localStorage) {
      localStorage.removeItem(obj);
    }
  }
}
