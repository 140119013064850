import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { AnnexureType } from "../models/AnnexureType.dto";
import { Observable } from "rxjs";
import { AgreementAnnexure } from "../models/agreement-annexure.dto";
@Injectable({
  providedIn: "root",
})
export class AgreementAnnexureService {
  baseUrl: string = "";
  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.apiURL;
  }

  public getAnnexureTypes(): Observable<AnnexureType[]> {
    return this.httpClient.get<AnnexureType[]>(
      `${this.baseUrl}/api/AgreementAnnexure/annexuretypes`
    );
  }

  public getEmployeById(EmployeeId: number) {
    return this.httpClient.get(
      `${this.baseUrl}/api/AgreementAnnexure/getemployee?EmployeeId=${EmployeeId}`
    );
  }
  public getEmploymentAgreementDate(EmployeeId: number) {
    return this.httpClient.get(
      `${this.baseUrl}/api/AgreementAnnexure/getagreementdate?EmployeeId=${EmployeeId}`
    );
  }
  public saveAnnexure(annexureDto: AgreementAnnexure) {
    return this.httpClient.post(
      `${this.baseUrl}/api/AgreementAnnexure/save`,
      annexureDto
    );
  }

  public updateAnnexure(annexureDto: AgreementAnnexure) {
    return this.httpClient.put(
      `${this.baseUrl}/api/AgreementAnnexure/update`,
      annexureDto
    );
  }

  public getAnnexure(annexureId: number):Observable<AgreementAnnexure>
  {

    return this.httpClient.get<AgreementAnnexure>(`${this.baseUrl}/api/AgreementAnnexure/getannexure?AnnexureId=${annexureId}`);
  }
  public getAnnexures():Observable<AgreementAnnexure[]>
  {

    return this.httpClient.get<AgreementAnnexure[]>(`${this.baseUrl}/api/AgreementAnnexure/getannexures`);
  }
  public getAnnexuresByEmployee(employeeId: number):Observable<AgreementAnnexure[]>
  {

    return this.httpClient.get<AgreementAnnexure[]>(`${this.baseUrl}/api/AgreementAnnexure/getannexuresbyemployee?EmployeeId=${employeeId}`);
  }

}
