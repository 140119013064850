export class AgreementAnnexure {
  Id: number;
  AssignedDate: Date;
  SubmittedDate?: Date;
  QueryRaisedDate?: Date;
  ApproveOrRejectDate?: Date;
  EmployeeId: number;
  AnnexureStatusId: number;
  AnnexureTypeId: number;
  PayRollDate: Date;
  PayPeriodFrom: Date;
  PayPeriodTo: Date;
  EmploymentAgreementDate: Date;
  OldPayRate: string;
  NewPayRate: string;
  BenifitsType:string;
  PaymentType: string;
  EmployerSignature?: string;
  EmployeeSignature?: string;
  AssignedBy: number;
  ApprovedOrRejectedBy?: number;
  QueryStatus: number;
  EmployeeIPAddress?: string;
  QueryRemarks?: string;
  ApproveOrRejectComment?: string;
  // following are additional properties, which are not in Entity Model.
  StatusMessage?: string;
  AnnexureStatus: string;
  AnnexureType: string;
  EmployerName: string;
  EmployeeName: string;

}
