import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { TokenService } from "app/token.service";

import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AnnexureGuard implements CanActivate {
  loggedInUserRoleId: any;
  loggedInUserId: any;

  constructor(

    private router: Router, private tokenService: TokenService

  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {

 
    this.loggedInUserRoleId =localStorage.getItem("LoggedUserRole");
    this.loggedInUserId = localStorage.getItem("LoggedUserID");


    if (this.loggedInUserRoleId != undefined && this.loggedInUserRoleId != null && this.loggedInUserRoleId != "" &&
    this.loggedInUserId != undefined && this.loggedInUserId != null && this.loggedInUserId != ""
    ) {
      
      if (this.loggedInUserRoleId === '1' || this.loggedInUserRoleId === '2') {
       
        return true;
      } else {
       
        this.router.navigate(["authentication/login"]);
        return false;
      }


    } else {
      
    
      this.router.navigate(["authentication/login"]);
        return false;

    }


   
  
  }
}
